<template>
  <div class="box">
    <van-list
      v-model="loading"
      :finished="finished"
      offset="100"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="product-box">
        <div
          class="product"
          v-for="item in list"
          :key="item.productId"
          @click="toInfo(item.productId)"
        >
          <div class="product-top">
            <div style="display: flex; align-items: center">
              <van-icon
                :name="
                  'https://img.weiqilile.com/bank/thumb/' +
                  item.institutionCode +
                  '.png'
                "
                size="20px"
                style="margin-right: 4px"
              />
              {{ item.name }}
            </div>
            <!-- <div class="product-tag">
              办理方式：{{
                item.method == 0
                  ? "线上+线下办理"
                  : item.method == 1
                  ? "线下办理"
                  : "线上办理"
              }}
            </div> -->
          </div>
          <van-row>
            <van-col span="8">
              <div class="price">{{ item.maxQuota }}万</div>
              <div class="product-name">最高额度</div>
            </van-col>
            <van-col span="8">
              <!-- <div class="rate">{{ item.lixi }}%</div>
              <div class="product-name">{{ item.lixiWenan }}</div> -->
              <div class="rate">{{ item.rate }}%</div>
              <div class="product-name">年化利率</div>
            </van-col>
            <van-col span="8">
              <div class="mode">{{ item.fangshi }}</div>
              <div class="product-name">还款方式</div></van-col
            >
          </van-row>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
import url from "@/service/url-config";
export default {
  data() {
    return {
      repayment: [], //产品还款方式
      loan_varieties_type: [], //产品贷款品种
      list: [],
      page: 0,
      size: 1000,
      loading: true,
      finished: false,
      noData: require("@/img/no-data.png"),
    };
  },
  created() {
    this.getDict();
  },
  methods: {
    //字典
    getDict() {
      this.$http.get(url.dict).then((res) => {
        res.data.forEach((v) => {
          this[v.name] = v.dictDetails;
        });
        this.getList();
      });
    },
    //收藏产品
    getList() {
      this.$http
        .get(url.getCollectPage + `?page=${this.page}&size=${this.size}`)
        .then((res) => {
          this.loading = false;
          let zidian = {};
          this.repayment.forEach((v) => {
            zidian[v.value] = v.label;
          });
          res.data.content.forEach((v) => {
            if (v.repayment && v.repayment.length > 0) {
              v.fangshi = "";
              let arr = v.repayment.split(",");
              arr.forEach((a) => {
                v.fangshi += zidian[a] + " ";
              });
            }
          });
          if (res.data.content.length < this.size) {
            this.finished = true;
          }
          this.loading = false;
          this.list = this.list.concat(res.data.content);
        });
    },
    //加载更多
    onLoad() {
      this.page = this.page + 1;
      this.getList();
    },
    //产品详情
    toInfo(id) {
      this.$router.push({
        path: "productInfo",
        query: {
          id: id,
          type: "putong",
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f5f5f5;
  min-height: calc(100vh - 80px);
  padding-top: 80px;
  .product-box {
    width: 355px;
    margin: 0 auto;
    margin-bottom: 15px;
    border-radius: 5px;
  }
  .product {
    width: calc(355px - 20px);
    height: calc(140px - 34px);
    // border-bottom: 1px solid #f3f3f3;
    padding: 17px 10px;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 8px;
    overflow: hidden;
  }
  .product-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 14px;
    font-size: 12px;
    font-weight: bold;
    color: #405080;
  }
  .product-tag {
    font-size: 10px;
    color: #f26659;
    background: #fdeeec;
    padding: 2px 5px;
    border-radius: 4px;
  }
  .price {
    font-size: 23px;
    color: #f55c00;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding-bottom: 2px;
  }
  .rate {
    font-size: 23px;
    color: #f55c00;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding-top: 4px;
    // padding-bottom: 5px;
  }
  .mode {
    font-size: 12px;
    font-weight: bold;
    color: #f55c00;
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 10px;
    padding-top: 6px;
  }
  .product-name {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #808aab;
  }

  .list-title {
    height: 42px;
    color: #555555;
    font-size: 12px;
    line-height: 42px;
    text-align: center;
    border-bottom: 1px solid #ededed;
  }
  .list {
    height: 42px;
    color: #555555;
    font-size: 12px;
    line-height: 42px;
    text-align: center;
    border-bottom: 1px solid #ededed;
  }
  .list-name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .red {
    color: #ef5446;
  }
  .blue {
    color: #1377ff;
  }

  .noMeasurement {
    width: 100%;
    text-align: center;
    margin-top: 73px;
  }
  .noMeasurement img {
    width: 120px;
    height: 113px;
  }
  .measurement-hint {
    font-size: 15px;
    color: #666666;
    margin-top: 26px;
  }
  .toMeasurement {
    width: 220px;
    height: 42px;
    background: #0084ff;
    border-radius: 10px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    line-height: 42px;
    margin: 0 auto;
    margin-top: 80px;
  }
}
</style>
